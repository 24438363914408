/* eslint-disable no-unsafe-optional-chaining */
import React, { ComponentClass, FC, useEffect, useState } from 'react';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { TextVariant } from '@naf/text';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import loadable from '@loadable/component';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import S from '../../styles/becomeAMember/StyledBecomeAMember';
import { DEFAULT_IMG_URL, MEMBERPAGES_URL } from '../../lib/constants';
import { Redirect } from './forms/Redirect';
import { Progressbar } from './components/ProgressBar';
import { actions as becomeAMemberActions } from '../../redux/modules/becomeAMember';
import { createEventPayload, useSendGTMEventOnce } from '../../hooks/useSendGTMEventOnce';
import { NAFGTMEvent } from '../../../../types/GTM/NAFGTMEvent';
import { useQueryParams } from '../../hooks/useQueryParameters';
import useSelector from '../../redux/typedHooks';
import { ProductID } from '../../lib/ProductID';
import { useFetchProfile } from '../../hooks/useFetchProfile';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import { actions } from '../../redux/modules/myMembership';
import { LoaderContent } from '../LoaderPage';
import { Addons } from './forms/Addons';
import { useGTMDataLayer } from '../../hooks/useGTMDataLayer';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { funnelPayload } from '../../../../types/gtmFunnel';

const Membership = loadable(() => import('./forms/Membership'), {
  resolveComponent: (components) => components.Membership,
});

const Members = loadable(() => import('./forms/Members'), {
  resolveComponent: (components) => components.Members,
});

interface Step {
  step:
    | FC<Partial<StepWizardChildProps & { setNumberOfMembers: (input: string) => void }>>
    | ComponentClass<Partial<StepWizardChildProps & { setNumberOfMembers: (input: string) => void }>>
    | string;
  name: string;
  type: 'FORM' | 'REDIRECT';
}

const documentTitle = (step: number | undefined) => {
  if (step === 2) return 'Bli Medlem - Personalia';
  if (step === 3) return 'Bli Medlem - Tilleggsvalg';
  if (step === 4) return 'Bli Medlem - Betaling';
  return 'Bli Medlem';
};

export const BecomeAMember = () => {
  // Fetch product once on mount
  const [didFetchBecomeAMember, setDidFetchBecomeAMember] = useState(false);
  const [didFetchBecomeAMemberWithCampaignCode, setDidFetchBecomeAMemberWithCampaignCode] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [numberOfMembers, setNumberOfMembers] = useState('1');
  const { simpleToken, richToken } = useAuth0Token();
  const queryParams = useQueryParams();

  const { profile } = useFetchProfile({ token: simpleToken });
  const [profileIsLoaded, setProfileIsLoaded] = useState(false);

  useEffect(() => {
    if (!didFetchBecomeAMember && !didFetchBecomeAMemberWithCampaignCode) {
      dispatch(becomeAMemberActions.getBecomeAMember.request(undefined));
      setDidFetchBecomeAMember(true);
    }
  }, [dispatch, didFetchBecomeAMember, didFetchBecomeAMemberWithCampaignCode]);

  useEffect(() => {
    if (!didFetchBecomeAMemberWithCampaignCode && queryParams?.campaignCode) {
      dispatch(becomeAMemberActions.getBecomeAMember.request(queryParams?.campaignCode));
      setDidFetchBecomeAMemberWithCampaignCode(true);
    }
  }, [dispatch, didFetchBecomeAMemberWithCampaignCode, queryParams?.campaignCode]);

  const {
    customerInformation,
    customerInformation: {
      meta: { fetchState },
    },
  } = useSelector((state) => state.myMembership);

  // Get customer information
  useEffect(() => {
    if (richToken && fetchState === 'initial') {
      dispatch(actions.getCustomerInformation.request(richToken));
    }
  }, [richToken, dispatch, fetchState]);

  useEffect(() => {
    if (customerInformation?.data?.membership != null && fetchState === 'success') {
      history.replace(`/${MEMBERPAGES_URL}/mitt-medlemskap`);
    }
  }, [customerInformation, history, fetchState]);

  const parameters = useQueryParams();
  const initialProduct = useSelector((state) => {
    if (typeof parameters !== 'undefined') {
      if (typeof parameters.product !== 'undefined') {
        return parameters && state.becomeAMember.data.products[parameters.product];
      }
      return state.becomeAMember.data.products[ProductID.MembershipWithRoadsideAssistance];
    }
    return undefined;
  });

  const membershipProduct = useSelector((state) => state.becomeAMember.data.products[ProductID.Membership]);

  const [productId, setProductId] = useState(initialProduct?.productNumber);

  useEffect(() => {
    if (initialProduct) setProductId(initialProduct.productNumber);
  }, [initialProduct]);

  // Google Analytics
  useSendGTMEventOnce(
    initialProduct &&
      createEventPayload({
        event: NAFGTMEvent.beginCheckout,
        ecommerce: {
          items: [
            {
              item_id: membershipProduct.productNumber,
              quantity: '1',
              item_name: 'Medlemskap',
              price: membershipProduct.productAppPrice[1]?.itemTotalPrice.toFixed(2),
              item_category2: 'Medlemskap',
              item_category3: 'Innmelding',
            },
            {
              item_id: '2020',
              quantity: '1',
              item_name: 'Veihjelp',
              price: (
                initialProduct.productAppPrice[1]?.itemTotalPrice - membershipProduct.productAppPrice[1]?.itemTotalPrice
              ).toFixed(2),
              item_category2: 'Medlemskap',
              item_category3: 'Tilleggstjenester',
            },
          ],
        },
      }),
    [initialProduct],
  );

  useSendGTMEventOnce(funnelPayload('bli-medlem', 1), []);

  const product = useSelector((state) => productId && state.becomeAMember.data.products[productId]);

  const steps: Step[] = [
    { step: Membership, name: 'Medlemskap', type: 'FORM' },
    { step: Members, name: numberOfMembers === '1' ? 'Om meg' : 'Om oss', type: 'FORM' },
    ...(product && product?.availableAddOnProducts
      ? [{ step: Addons, name: 'Tilleggsvalg', type: 'FORM' } as Step]
      : []),
    { step: 'Betaling', name: 'Betaling', type: 'REDIRECT' },
  ];

  const dataLayer = useGTMDataLayer();
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const sendVirtualPageView = ({ activeStep }: { activeStep: number }) => {
    dataLayer?.push({
      event: 'virtualPageView',
      page: history.location.pathname,
      pageUrl: `${baseUrl}${history.location.pathname}${history.location.search}`,
      pageTitle: documentTitle(activeStep),
    });
  };
  const windowPath = useWindowLocation();

  return (
    <LayoutWithMainContent
      title="Bli Medlem"
      description="Her kan du bli NAF-medlem"
      url={windowPath}
      imgUrl={DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'Bli Medlem',
        pageCategory: '',
        contentId: 'become-a-member',
      }}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.BecomeAMember,
      }}
    >
      {fetchState !== 'loading' ? (
        <Grid className="Wrapper">
          <GridRow className="GridRow">
            <GridCol s="12" m="12" l="12" xl="12">
              <S.Heading variant={TextVariant.Header1}>Bli NAF-medlem</S.Heading>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol s="12" m="12" l="12" xl="12">
              <StepWizard
                transitions={{}}
                isLazyMount
                onStepChange={sendVirtualPageView}
                nav={<Progressbar steps={steps} />}
              >
                <Membership setNumberOfMembers={setNumberOfMembers} setProductId={setProductId} />
                <Members profile={profile} profileIsLoaded={profileIsLoaded} setProfileIsLoaded={setProfileIsLoaded} />
                {product && product.availableAddOnProducts && <Addons />}
                <Redirect />
              </StepWizard>
            </GridCol>
          </GridRow>
        </Grid>
      ) : (
        <LoaderContent />
      )}
    </LayoutWithMainContent>
  );
};
